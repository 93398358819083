<template>
  <div class="main2">
      <header class="centerH">
      <a @click="() => $router.go(-1)" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
            <img class="logo" src="@/assets/icon.png" />
    </header>
    <div class="content_bg">
        <p>跳转中</p>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { wechatcallback } from "@/api/api";
export default {
  name:'callback',
  data(){
    return {
      code:""
    }
  },
  methods:{
     getQueryString(name) { 
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"); 
        var r = window.location.search.substr(1).match(reg); 
        if (r != null) return unescape(r[2]); 
        return null; 
    } 
  },
  mounted(){
    var url = window.location.search.substr(1);

      this.code = url.split("=")[1].split("&")[0]
        // this.code = this.$route.query.code;
        console.log("code",this.code)
        wechatcallback({'code':this.code}).then(res=>{
          Vue.ls.set("TOKEN", res.data.data.access_token, 7 * 24 * 60 * 60 * 1000)
          // Vue.$cookies.set("TOKEN",res.data.data.access_token)
           this.$router.push({ name: 'Dashboard' })
        })
  }
};
</script>
<style>
.main2 {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-image: url("/img/background.jpg");
  background-size: 100% 100%;
}

.content_bg {
  position: relative;
  top: 30%;
  vertical-align: middle;
  margin: 0 auto;
  background-color: #fff;
  width: 15rem;
  height: 15rem;
  text-align: center;
  border-radius: 1rem;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

/* .content_bg img {
  width: 5rem;
  vertical-align: middle;
  margin-top: 3rem;
} */

.content_bg p {
position: relative;
  top: 40%;
}
</style>
