<template>
  <div>
   <router-view/>
  </div>
</template>
<script>
  window.onload = function() {
  document.addEventListener('touchstart', function(event) {
  if (event.touches.length > 1) {
  event.preventDefault()
  }
  })
  document.addEventListener('gesturestart', function(event) {
  event.preventDefault()
  })
      $(".activity_list .item").hover(function() {
      $(this)
        .stop(true)
        .animate({ width: "100%" }, 300)
        .siblings()
        .stop(true)
        .animate({ width: "18%" }, 300);
    });
  }
  </script>