var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('header',{staticClass:"centerH"},[_c('a',{staticClass:"btn-back",on:{"click":function () { return _vm.$router.push({ name: 'Dashboard' }); }}},[_c('img',{attrs:{"src":require("@/assets/img-back.png"),"alt":""}})]),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/icon.png")}})]),_c('div',{staticClass:"choice_time"},[_c('p',{staticClass:"time_label"},[_vm._v("选择日期")]),_c('div',{staticClass:"dateList"},_vm._l((_vm.dateList),function(item,i){return _c('div',{key:i,staticClass:"dateItem",class:{
        today: item.dates == new _vm.moment().format('YYYY-MM-DD'),
        select: item.dates == _vm.nowSelect.dates
      },on:{"click":function($event){return _vm.checkWeek(item)}}},[_c('span',{staticClass:"days"},[_vm._v(_vm._s(_vm._f("weekFilter")(item.weeks)))]),_c('span',{staticClass:"dates"},[_vm._v(_vm._s(_vm._f("dateFilter")(item.dates)))])])}),0),(_vm.nowSelect.timeList && _vm.nowSelect.timeList.length > 0)?_c('div',{staticClass:"timeQuantum",class:{
      more: _vm.nowSelect.timeList.length > 3,
      few: _vm.nowSelect.timeList.length <= 3
    }},_vm._l((_vm.nowSelect.timeList),function(item){return _c('div',{key:item.guid,staticClass:"timeItem",class:{
        noRemain: item.remainingVotes == 0,
        select: _vm.nowSelectTime.guid == item.guid
      },on:{"click":function($event){return _vm.checkTime(item)}}},[_c('div',{staticClass:"timeItemFlexBox"},[_c('span',{staticClass:"timeSection"},[_vm._v(" "+_vm._s(item.admissionStartTime)+" ")])])])}),0):_vm._e()]),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.activity.name))]),_c('img',{staticClass:"poster",attrs:{"src":_vm.activity.faceImage}})]),_c('div',{staticClass:"footer next"},[_c('a-button',{staticClass:"btn next_btn",on:{"click":_vm.handleNext}},[_vm._v("预约")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }