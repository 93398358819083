var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main2"},[_c('header',{staticClass:"centerH"},[_c('a',{staticClass:"btn-back",on:{"click":function () { return _vm.$router.go(-1); }}},[_c('img',{attrs:{"src":require("@/assets/img-back.png"),"alt":""}})]),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/icon.png")}})]),_c('img',{staticClass:"fangxiang",attrs:{"src":require("@/assets/north.png")}}),_c('div',{staticClass:"floor"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f1),expression:"f1"}],staticClass:"f1",attrs:{"src":require("@/assets/floor1.png")},on:{"click":_vm.handleShowImage}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f2),expression:"f2"}],staticClass:"f2",attrs:{"src":require("@/assets/floor2.png")},on:{"click":_vm.handleShowImage}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f3),expression:"f3"}],staticClass:"f3",attrs:{"src":require("@/assets/floor3.png")},on:{"click":_vm.handleShowImage}})]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f1),expression:"f1"}],staticClass:"lc",attrs:{"src":require("@/assets/f1_bottom.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f2),expression:"f2"}],staticClass:"lc",attrs:{"src":require("@/assets/f2_bottom.png")}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f3),expression:"f3"}],staticClass:"lc",attrs:{"src":require("@/assets/f3_bottom.png")}}),_c('div',{staticClass:"btn_group"},[_c('a-button',{class:{ btn_checkbox: !_vm.f3, btn_checkbox2: _vm.f3 },attrs:{"shape":"circle"},on:{"click":function () {
          (_vm.f4 = false), (_vm.f3 = true), (_vm.f2 = false), (_vm.f1 = false);
        }}},[_vm._v("F3")]),_c('a-button',{class:{ btn_checkbox: !_vm.f2, btn_checkbox2: _vm.f2 },attrs:{"shape":"circle"},on:{"click":function () {
          (_vm.f4 = false), (_vm.f3 = false), (_vm.f2 = true), (_vm.f1 = false);
        }}},[_vm._v("F2")]),_c('a-button',{class:{ btn_checkbox: !_vm.f1, btn_checkbox2: _vm.f1 },attrs:{"shape":"circle"},on:{"click":function () {
          (_vm.f4 = false), (_vm.f3 = false), (_vm.f2 = false), (_vm.f1 = true);
        }}},[_vm._v("F1")])],1),_c('a-modal',{attrs:{"centered":"","closable":false,"footer":null,"destroyOnClose":true},on:{"cancel":_vm.handleClose},model:{value:(_vm.modal2Visible),callback:function ($$v) {_vm.modal2Visible=$$v},expression:"modal2Visible"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f1),expression:"f1"}],attrs:{"src":require("@/assets/floor1.png")},on:{"click":function () {
          _vm.modal2Visible = false;
        }}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f2),expression:"f2"}],attrs:{"src":require("@/assets/floor2.png")},on:{"click":function () {
          _vm.modal2Visible = false;
        }}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f3),expression:"f3"}],attrs:{"src":require("@/assets/floor3.png")},on:{"click":function () {
          _vm.modal2Visible = false;
        }}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.f4),expression:"f4"}],attrs:{"src":require("@/assets/f4.png")},on:{"click":function () {
          _vm.modal2Visible = false;
        }}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }