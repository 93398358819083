<template>
  <div class="main">
    <header class="centerH">
      <a @click="() => $router.push({ name: 'Dashboard' })" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>
    <!-- style="display: none" -->
    <div class="choice_time" style="display: none">
      <p class="time_label">选择日期</p>
      <ul class="week">
        <li v-for="(item, index) in weeks" :key="index">
          {{ item | weekFilter }}
        </li>
      </ul>
      <ul class="date">
        <li
          v-for="(item, index) in sessionList"
          :key="index"
          :class="{
            active:
              !!item.sessionList && checkId.guid == item.sessionList.guid,
          }"
          @click="handleDate(item)"
        >
          <div
            :class="{
              jin: new moment().format('YYYY-MM-DD') == item.date,
              nojin: new moment().format('YYYY-MM-DD') != item.date,
            }"
          >
            {{ new moment().format("YYYY-MM-DD") == item.date ? "今" : "" }}
          </div>
          <p>{{ item.date | dateFilter }}</p>
          <!-- <p>
            {{
              !!item.sessionList && item.sessionList.sessionStatus !=7 && item.sessionList.sessionStatus!=8
                ? "余" + item.sessionList.remainingVotes
                : "不可约"
            }}
          </p> -->
                <p>
            {{
              !!item.sessionList && item.sessionList.sessionStatus !=7 && item.sessionList.sessionStatus!=8
                ? ((item.sessionList.remainingVotes>0)?"有票":"已满")
                : "不可约"
            }}
          </p>
          <!-- <p>{{ item | dateFilter }}</p> -->
        </li>
      </ul>
        <p class="schedule_time" v-if="checkId.admissionStartTime!=null">可约时段 {{checkId.admissionStartTime}}</p>
    </div>
    <div class="content">
      <p class="title">{{ activity.name }}</p>
      <!-- <p class="sub_title">水墨艺术展</p> -->
      <img class="poster" :src="activity.faceImage" />
      <!-- <div class="summary" v-html="activity.detailsBody"></div> -->
    </div>
    <div class="footer next" style="display: none">
      <a-button class="btn next_btn" @click="handleNext">预约</a-button>
    </div>
  </div>
</template>
<script>
import { getActivityInfo, verifyCurrentMemberOrder } from "@/api/api";
import moment from "moment";
export default {
  name: "activity",
  data() {
    return {
      activity: {},
      checkId: {},
      dates: [],
      weeks: [],
      sessionList: []
    };
  },
  filters: {
    dateFilter: function(value) {
      return value.substring(5, value.length);
    },
    weekFilter: function(value) {
      if (value == "Monday") {
        return "一";
      }
      if (value == "Tuesday") {
        return "二";
      }
      if (value == "Wednesday") {
        return "三";
      }
      if (value == "Thursday") {
        return "四";
      }
      if (value == "Friday") {
        return "五";
      }
      if (value == "Saturday") {
        return "六";
      }
      if (value == "Sunday") {
        return "日";
      }
    },
  },
  methods: {
    moment,
    handleDate(item) {
      if (!!item.sessionList && item.sessionList.sessionStatus !=7 && item.sessionList.sessionStatus!=8) {
        this.checkId = item.sessionList;
      }
    },
    handleNext() {
      if (this.checkId.guid == null) {
        this.$message.error("请选择参展日期");
        return;
      }
      verifyCurrentMemberOrder({
        activityId: this.activity.guid,
        sessionId: this.checkId.guid,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$router.push({
            name: "SubscribeForm",
            query: {
              activityId: this.activity.guid,
              sessionId: this.checkId.guid,
              onePersonMaxNum: this.checkId.onePersonMaxNum,
            },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    loadData() {
      var id = this.$route.params.id;
      getActivityInfo(id).then((res) => {
        console.log("res", res);
        this.activity = res.data.data;
        // this.checkId = this.activity.sessionList[0].guid;
        for (var i = 0; i < this.dates.length; i++) {
          var obj = new Object();
          obj.date = this.dates[i];
          if (
            this.activity.sessionList != null &&
            this.activity.sessionList.length > 0
          ) {
            for (var j = 0; j < this.activity.sessionList.length; j++) {
              if (this.activity.sessionList[j].freeTime == this.dates[i]) {
                obj.sessionList = this.activity.sessionList[j];
              }
            }
          }
          this.sessionList.push(obj);
        }
        console.log("sessionList", this.sessionList);
      });
    },
  },
  mounted() {
    for (var i = 0; i < 7; i++) {
      this.dates.push(new moment().add(i, "days").format("YYYY-MM-DD"));
      this.weeks.push(new moment().add(i, "days").format("dddd"));
    }
    this.loadData();
  },
};
</script>
<style>
.main {
  padding-top: 0rem;
  overflow: auto;
  margin-top: 0rem;
}
.main .choice_time {
  background-color: #fff;
  width: 100%;
  /* margin-top: 3.2rem; */
}
.main .choice_time .time_label {
  text-align: left;
  margin-left: 1rem;
  padding-top: 1rem;
  margin-bottom: 1.5rem;
  color: #000000;
  font-size: 1.3rem;
}
.week {
  width: 100%;
  height: 2rem;
  text-align: center;
  list-style: none;
}
.week li {
  float: left;
  width: 14.24%;
  text-align: center;
  font-size: 1rem;
}

.date {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 4rem;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  list-style: none;
}
.date li {
  float: left;
  margin: 0 auto;
  width: 14.24%;
  height: 4rem;
  font-size: 0.9rem;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}

.date li:last-child {
  border-right: 1px solid #999;
}

.date .active {
  color: #fff;
  background: #000;
}
.main .date li p {
  display: inline-flex;
  text-align: center;
}
.date li .jin {
  margin: 0 auto;
  width: 1rem;
  height: 1rem;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #fff;
  background: red;
  float: right;
  text-align: center;
}
.date li .nojin {
  width: 1rem;
  height: 1rem;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #fff;
  background: transparent;
  float: right;
}
.schedule_time {
  background-color: #fff;
  height: 2rem;
  line-height: 2rem;
}
.next {
  width: 100%;
  height: 4rem;
}

.next_btn {
  width: 80%;
  height: 3rem;
}

.main .content {
  width: 90%;
  margin: 0 auto;
  /* padding-top: 2rem; */
  padding-bottom: 5rem;
  overflow: auto;
}
.main .content .title {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}
.main .content .sub_title {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}
.main .content .poster {
  margin-top: 1rem;
  width: 100%;
}
.main .content .summary {
  margin-top: 1rem;
  text-align: left;
}

.main .content .summary img {
  width: 100%;
}
</style>
