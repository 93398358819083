<template>
  <div class="main">
    <header class="centerH">
      <a @click="() => $router.push({ name: 'Dashboard' })" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>
    <!-- style="display: none;" -->
    <div class="choice_time">
      <p class="time_label">选择日期</p>
      <!--<ul class="week">
        <li v-for="(item, index) in weeks" :key="index">
          {{ item | weekFilter }}
        </li>
      </ul>
      <ul class="date">
        <li
          v-for="(item, index) in sessionList"
          :key="index"
          :class="{
            active:
              !!item.sessionList && checkId.guid == item.sessionList.guid,
          }"
          @click="handleDate(item)"
        >
          <div
            :class="{
              jin: new moment().format('YYYY-MM-DD') == item.date,
              nojin: new moment().format('YYYY-MM-DD') != item.date,
            }"
          >
            {{ new moment().format("YYYY-MM-DD") == item.date ? "今" : "" }}
          </div>
          <p>{{ item.date | dateFilter }}</p>
          <p>
            {{
              !!item.sessionList && item.sessionList.sessionStatus !=7 && item.sessionList.sessionStatus!=8
                ? "余" + item.sessionList.remainingVotes
                : "不可约"
            }}
          </p>
          &lt;!&ndash; <p>{{ item | dateFilter }}</p> &ndash;&gt;
        </li>
      </ul>
        <p class="schedule_time" v-if="checkId.admissionStartTime!=null">可约时段 {{checkId.admissionStartTime}}</p>-->
      <div class="dateList">
        <div @click="checkWeek(item)" class="dateItem" v-for="(item,i) in dateList" :key="i" :class="{
          today: item.dates == new moment().format('YYYY-MM-DD'),
          select: item.dates == nowSelect.dates
        }"> <!--today select-->
          <span class="days">{{item.weeks | weekFilter}}</span>
          <span class="dates">{{item.dates | dateFilter}}</span>
        </div>
      </div>

      <div v-if="nowSelect.timeList && nowSelect.timeList.length > 0" class="timeQuantum" :class="{
        more: nowSelect.timeList.length > 3,
        few: nowSelect.timeList.length <= 3
      }"> <!--more few-->

        <div @click="checkTime(item)" class="timeItem" v-for="item in nowSelect.timeList" :key="item.guid" :class="{
          noRemain: item.remainingVotes == 0,
          select: nowSelectTime.guid == item.guid
        }"> <!--noRemain select-->
          <div class="timeItemFlexBox">
            <span class="timeSection">
              {{item.admissionStartTime}}
            </span>
            <!--<span class="remainCount">(余 {{item.remainingVotes}})</span>-->
            <!-- <span class="remainCount" v-if="item.remainingVotes >0">有票</span> -->
            <!-- <span class="remainCount" v-if="item.remainingVotes <=0">已满</span> -->
          </div>
        </div>

      </div>
    </div>
    <div class="content">
      <p class="title">{{ activity.name }}</p>
      <!-- <p class="sub_title">水墨艺术展</p> -->
      <img class="poster" :src="activity.faceImage" />
      <!-- <div class="summary" v-html="activity.detailsBody"></div> -->
    </div>
    <div class="footer next">
      <a-button class="btn next_btn" @click="handleNext">预约</a-button>
    </div>
  </div>
</template>

<script>
import { getActivityInfo, verifyCurrentMemberOrder,getMuseumActivityInfo } from "@/api/api";
import moment from "moment";
export default {
  name: "activity",
  data() {
    return {
      activity: {},
      checkId: {},
      // dates: [],
      // weeks: [],
      // sessionList: [],

      dateList: [],
      nowSelect: {},
      nowSelectTime: {}
    };
  },
  filters: {
    dateFilter: function(value) {
      return value.substring(5, value.length);
    },
    weekFilter: function(value) {
      if (value == "Monday") {
        return "周一";
      }
      if (value == "Tuesday") {
        return "周二";
      }
      if (value == "Wednesday") {
        return "周三";
      }
      if (value == "Thursday") {
        return "周四";
      }
      if (value == "Friday") {
        return "周五";
      }
      if (value == "Saturday") {
        return "周六";
      }
      if (value == "Sunday") {
        return "周日";
      }
    },
  },
  methods: {
    moment,
    checkWeek(item){
      this.nowSelect = item
      this.nowSelectTime = {}
    },
    checkTime(item){
      if(item.remainingVotes <= 0) return;
      this.nowSelectTime = item
    },
    handleDate(item) {
      console.log(item)
      /*if (!!item.sessionList && item.sessionList.sessionStatus !=7 && item.sessionList.sessionStatus!=8) {
        this.checkId = item.sessionList;
      }*/
    },
    handleNext() {
      if (!this.nowSelectTime || !this.nowSelectTime.guid) {
        this.$message.error("请选择参展时间段");
        return;
      }
      verifyCurrentMemberOrder({
        activityId: this.activity.guid,
        sessionId: this.nowSelectTime.guid,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$router.push({
            name: "SubscribeForm",
            query: {
              activityId: this.activity.guid,
              sessionId: this.nowSelectTime.guid,
              onePersonMaxNum: this.nowSelectTime.onePersonMaxNum,
            },
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    loadData() {
      getMuseumActivityInfo().then(res=>{
         var id = res.data.rows[0].guid;
        getActivityInfo(id).then((res) => {
        this.activity = res.data.data;
        // this.checkId = this.activity.sessionList[0].guid;
        /*for (var i = 0; i < this.dates.length; i++) {
          var obj = new Object();
          obj.date = this.dates[i];
          if (this.activity.sessionList != null && this.activity.sessionList.length > 0) {

            for (var j = 0; j < this.activity.sessionList.length; j++) {

              if (this.activity.sessionList[j].freeTime == this.dates[i]) {

                obj.sessionList = this.activity.sessionList[j];

              }
            }
          }
          this.sessionList.push(obj);
        }*/
        // console.log("sessionList", this.sessionList);
        let substitute = JSON.parse(JSON.stringify(this.dateList));
        this.activity.sessionList.forEach(item => {
          this.dateList.forEach((view, i) => {
            if(new Date(item.freeTime).getTime() == new Date(view.dates).getTime()) {
              substitute[i].timeList.push(item)
            }
          })
        });
        this.dateList = substitute;
        this.nowSelect = substitute[0];
      });
      })

    },
  },
  mounted() {
    for (var i = 0; i < 7; i++) {
      // this.dates.push(new moment().add(i, "days").format("YYYY-MM-DD"));
      // this.weeks.push(new moment().add(i, "days").format("dddd"));
      this.dateList.push({
        weeks: new moment().add(i, "days").format("dddd"),
        dates: new moment().add(i, "days").format("YYYY-MM-DD"),
        timeList: []
      })
    }
    this.loadData();
  },
};
</script>

<style>
.main {
  padding-top: 0rem;
  overflow: auto;
  margin-top: 0rem;
}
.main .choice_time {
  background-color: #fff;
  width: 100%;
  /* margin-top: 3.2rem; */
}

.main .choice_time .dateList {
  width: 100%;
  height: 5.21rem;
  box-sizing: border-box;
  display: flex;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eee;
}
.main .choice_time .dateList .dateItem {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main .choice_time .dateList .dateItem span {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.93rem;
  color: #555555;
  line-height: 1.5rem;
}
.main .choice_time .dateList .dateItem.today span {
  color: #119cf4;
}
.main .choice_time .dateList .dateItem.select {
  position: relative;
}
.main .choice_time .dateList .dateItem.select::after {
  position: absolute;
  bottom: -2px;
  left: 0;
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  background-color: #fb0102;
}
.main .choice_time .dateList .dateItem.select span {
  color: #fb0102;
}
.main .choice_time .timeQuantum {
  width: 100%;
  height: 6.18rem;
}
.main .choice_time .timeQuantum.few {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0.875rem;
}
.main .choice_time .timeQuantum.few .timeItem {
  display: inline-block;
  height: 4.375rem;
  flex: 1;
  border-radius: 0.8rem;
  border: 1px solid #dcdada;
  margin-right: 0.6rem;
}
.main .choice_time .timeQuantum.few .timeItem span {
  line-height: 1.4rem;
  color: #000;
}
.main .choice_time .timeQuantum.few .timeItem:last-child {
  margin-right: 0;
}
.main .choice_time .timeQuantum.few .timeItem.select {
  border-color: #fb0102;
  background-color: #fef8f8;
}
.main .choice_time .timeQuantum.few .timeItem.select span:last-child {
  color: #fb0102;
}
.main .choice_time .timeQuantum.few .timeItem.select span:first-child {
  color: #333333;
  font-weight: 700;
}
.main .choice_time .timeQuantum.few .timeItem.noRemain {
  background-color: #f7f7f7;
}
.main .choice_time .timeQuantum.few .timeItem.noRemain span {
  color: #999999;
}
.main .choice_time .timeQuantum.few .timeItem .timeItemFlexBox {
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.main .choice_time .timeQuantum.more {
  line-height: 4.8rem;
  box-sizing: border-box;
  padding: 0 0.875rem;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}
.main .choice_time .timeQuantum.more .timeItem {
  width: 10.28rem;
  display: inline-block;
  height: 4.375rem;
  border-radius: 0.8rem;
  border: 1px solid #dcdada;
  margin-right: 0.6rem;
}
.main .choice_time .timeQuantum.more .timeItem span {
  line-height: 1.4rem;
  color: #000;
}
.main .choice_time .timeQuantum.more .timeItem:last-child {
  margin-right: 0;
}
.main .choice_time .timeQuantum.more .timeItem.select {
  border-color: #fb0102;
  background-color: #fef8f8;
}
.main .choice_time .timeQuantum.more .timeItem.select span:last-child {
  color: #fb0102;
}
.main .choice_time .timeQuantum.more .timeItem.select span:first-child {
  color: #333333;
  font-weight: 700;
}
.main .choice_time .timeQuantum.more .timeItem.noRemain {
  background-color: #f7f7f7;
}
.main .choice_time .timeQuantum.more .timeItem.noRemain span {
  color: #999999;
}
.main .choice_time .timeQuantum.more .timeItem .timeItemFlexBox {
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}



.main .choice_time .time_label {
  text-align: left;
  margin-left: 1rem;
  padding-top: 1rem;
  margin-bottom: 1.5rem;
  color: #000000;
  font-size: 1.3rem;
}
.week {
  width: 100%;
  height: 2rem;
  text-align: center;
  list-style: none;
}
.week li {
  float: left;
  width: 14.24%;
  text-align: center;
  font-size: 1rem;
}

.date {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 4rem;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
  list-style: none;
}
.date li {
  float: left;
  margin: 0 auto;
  width: 14.24%;
  height: 4rem;
  font-size: 0.9rem;
  border-left: 1px solid #999;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}

.date li:last-child {
  border-right: 1px solid #999;
}

.date .active {
  color: #fff;
  background: #000;
}
.main .date li p {
  display: inline-flex;
  text-align: center;
}
.date li .jin {
  margin: 0 auto;
  width: 1rem;
  height: 1rem;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #fff;
  background: red;
  float: right;
  text-align: center;
}
.date li .nojin {
  width: 1rem;
  height: 1rem;
  font-size: 0.7rem;
  line-height: 1rem;
  color: #fff;
  background: transparent;
  float: right;
}
.schedule_time {
  background-color: #fff;
  height: 2rem;
  line-height: 2rem;
}
.next {
  width: 100%;
  height: 4rem;
}

.next_btn {
  width: 80%;
  height: 3rem;
}

.main .content {
  width: 90%;
  margin: 0 auto;
  /* padding-top: 2rem; */
  padding-bottom: 5rem;
  overflow: auto;
}
.main .content .title {
  color: #000;
  font-size: 1.2rem;
  font-weight: bold;
}
.main .content .sub_title {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
}
.main .content .poster {
  margin-top: 1rem;
  width: 100%;
}
.main .content .summary {
  margin-top: 1rem;
  text-align: left;
}

.main .content .summary img {
  width: 100%;
}
</style>
