<template>
  <div class="main2">
    <header class="centerH">
      <a @click="() => $router.go(-1)" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>
    <img class="fangxiang" src="@/assets/north.png" />
    <div class="floor">
      <img
        class="f1"
        v-show="f1"
        @click="handleShowImage"
        src="@/assets/floor1.png"
      />
      <img
        class="f2"
        v-show="f2"
        @click="handleShowImage"
        src="@/assets/floor2.png"
      />
      <img
        class="f3"
        v-show="f3"
        @click="handleShowImage"
        src="@/assets/floor3.png"
      />
      <!-- <img
        class="f4"
        v-show="f4"
        @click="handleShowImage"
        src="@/assets/f4.png"
      /> -->
    </div>
    <img class="lc" v-show="f1" src="@/assets/f1_bottom.png" />
    <img class="lc" v-show="f2" src="@/assets/f2_bottom.png" />
    <img class="lc" v-show="f3" src="@/assets/f3_bottom.png" />
    <!-- <img class="lc" v-show="f4" src="@/assets/f4_bottom.png" /> -->
    <div class="btn_group">
      <!-- <a-button
        :class="{ btn_checkbox: !f4, btn_checkbox2: f4 }"
        shape="circle"
        @click="
          () => {
            (f4 = true), (f3 = false), (f2 = false), (f1 = false);
          }
        "
        >F4</a-button
      > -->
      <a-button
        :class="{ btn_checkbox: !f3, btn_checkbox2: f3 }"
        shape="circle"
        @click="
          () => {
            (f4 = false), (f3 = true), (f2 = false), (f1 = false);
          }
        "
        >F3</a-button
      >
      <a-button
        :class="{ btn_checkbox: !f2, btn_checkbox2: f2 }"
        shape="circle"
        @click="
          () => {
            (f4 = false), (f3 = false), (f2 = true), (f1 = false);
          }
        "
        >F2</a-button
      >
      <a-button
        :class="{ btn_checkbox: !f1, btn_checkbox2: f1 }"
        shape="circle"
        @click="
          () => {
            (f4 = false), (f3 = false), (f2 = false), (f1 = true);
          }
        "
        >F1</a-button
      >
    </div>
    <a-modal
      v-model="modal2Visible"
      centered
      :closable="false"
      :footer="null"
      :destroyOnClose="true"
      @cancel="handleClose"
    >
      <img
        @click="
          () => {
            modal2Visible = false;
          }
        "
        v-show="f1"
        src="@/assets/floor1.png"
      />
      <img
        @click="
          () => {
            modal2Visible = false;
          }
        "
        v-show="f2"
        src="@/assets/floor2.png"
      />
      <img
        @click="
          () => {
            modal2Visible = false;
          }
        "
        v-show="f3"
        src="@/assets/floor3.png"
      />
      <img
        @click="
          () => {
            modal2Visible = false;
          }
        "
        v-show="f4"
        src="@/assets/f4.png"
      />
    </a-modal>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  data() {
    return {
      f1: true,
      f2: false,
      f3: false,
      f4: false,
      modal2Visible: false,
    };
  },
  methods: {
    handleShowImage() {
      this.modal2Visible = true;
    },
  },
  mounted() {
    var num = 1;
    function getDistance(p1, p2) {
      var x = p2.pageX - p1.pageX,
        y = p2.pageY - p1.pageY;
      return Math.sqrt(x * x + y * y);
    }
    $("img").on("touchmove", function(e) {
      // e.preventDefault();
      // 一根 手指 执行 目标元素移动 操作
      // console.log(e)
      if (e.originalEvent.touches.length == 1) {
      }

      // 2 根 手指执行 目标元素放大操作
      if (e.originalEvent.touches.length >= 2) {
        // alert("2")
        //得到第二组两个点
        var now = e.originalEvent.touches;
        Math.abs(
          e.originalEvent.touches[0].pageX - e.originalEvent.touches[1].pageX
        );
        var start = e.originalEvent.changeTouches;
        Math.abs(
          e.originalEvent.changeTouches[0].pageX -
            e.originalEvent.changeTouches[1].pageX
        );
        alert(getDistance(now[0], now[1]) < getDistance(start[0], start[1]));
        if (getDistance(now[0], now[1]) < getDistance(start[0], start[1])) {
          //缩小
          alert(4);
          if (num > 0.4) {
            num -= 0.01;
            $("img").css("transform", "scale(" + num + ")");
          }
        } else {
          //放大
          alert(5);
          if (num < 2) {
            num += 0.01;
            $("img").css("transform", "scale(" + num + ")");
          }
        }
      }
    });
  },
};
</script>
<style scoped>
.main2 {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-image: url("/img/background.jpg");
  background-size: 100% 100%;
  /* margin-top: 3rem; */
}
.floor {
  position: fixed;
  top: 30%;
  margin: 0 auto;
  height: 100%;
}

.main2 .f1 {
  position: fixed;
  bottom: 15%;
  width: 100%;
  z-index: 999;
}
.main2 .f2 {
  position: fixed;
  bottom: 25%;
  width: 100%;
  z-index: 999;
}
.main2 .f3 {
  position: fixed;
  bottom: 45%;
  width: 100%;
  z-index: 999;
}
.main2 .f4 {
  position: fixed;
  top: 25%;
  width: 100%;
  z-index: 999;
}
.main2 .fangxiang {
  float: right;
  width: 5rem;
  margin-top: 3rem;
}
.main2 .lc {
  position: fixed;
  bottom: 5%;
  width: 100%;
  height: 8rem;
  object-fit: cover;
  object-position: left bottom;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

.main2 .btn_group {
  position: fixed;
  top: 35%;
  right: 10%;
  z-index: 999;
  width: 1rem;
  height: 5rem;
}

.btn_checkbox {
  background-color: #fff;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.btn_checkbox2 {
  background-color: #000;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.btn_checkbox span {
  display: inline-block;
  color: #000;
}

.btn_checkbox2 span {
  display: inline-block;
  color: #fff;
}

.ant-modal-root >>> .ant-modal-content {
  background-color: transparent;
  box-shadow: none;
}
</style>
