<template>
  <div class="main2">
	<header class="centerH">
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>

	<div class="form_div">
		<form :form="form" layout="vertical">
			<div class="form_item float_left">
				<p class="form_label">手机号</p>
				<input
				v-model="phoneNum"
				class="input_phone"
				maxlength="11"
				placeholder="请输入手机号"
				/>
				<span class="v_code" v-show="codeshow" @click="sendCode"
				>发送验证码</span
				>
				<span class="v_code" v-show="!codeshow">{{ codeLabelMsg }}</span>
			</div>

			<div class="form_item float_left">
				<p class="form_label">验证码</p>
				<input
					v-model="verifySmsCode"
					class="input"
					maxlength="6"
					placeholder="请输入验证码"
				/>
			</div>

			<div>
				<p class="tips_label">为保证后期更好的进行服务，请在此处绑定您的手机号</p>
			</div>

			<div class="footer submit">
				
				<div>
					<a-button class="btn" @click="handleSubmit"
						>绑定手机号并登录</a-button
					>
				</div>
			</div>
		</form>

     
	</div>

  </div>
</template>

<script>
import { sendVerifyCode,bindPhoneNum  } from "@/api/api";
import Vue from 'vue'
export default {
	data(){
		return{
				codeLabelMsg: "发送验证码",
				phoneNum:null,//手机号
				verifySmsCode:null,//验证码
				codeshow: true,
				form:{

				},
				memberAuthId:null,//回调页面传递过来的msg
				queryParams:{

				}
		}
	},
	created(){
		//获取回调页面传递过来的msg
		this.memberAuthId = this.$route.query.memberAuthId;
	},
	methods:{
		/** 发送验证码 */
		sendCode(){
			if (this.phoneNum != null && this.phoneNum.length == 11) {
			this.codeshow = false;
			var countdown = 60;
			this.codeLabelMsg = "(" + countdown + ")";
			var timer = setInterval(() => {
			if (countdown <= 0) {
				clearInterval(timer);
				this.codeshow = true;
				return;
			}
			countdown--;
			this.codeLabelMsg = "(" + countdown + ")";
			}, 1000);
			sendVerifyCode({ phoneNum: this.phoneNum }).then((res) => {
				console.log("发送验证码",res);
			});
		} else {
			// alert("请输入手机号");
			this.$message.error("请输入手机号");
		}

		},
		/** 提交按钮 */
		handleSubmit(){
			// console.log("手机号",this.phoneNum);
			// console.log("验证码",this.verifySmsCode);

			if (this.phoneNum == null || this.phoneNum == "") {
				this.$message.error("请输入手机号");
				return;
			}
			if (this.phoneNum.length != 11) {
				this.$message.error("请输入正确的手机号");
				return;
			}
			if (this.verifySmsCode == null || this.verifySmsCode == "") {
				this.$message.error("请输入验证码");
				return;
			}
			if (this.verifySmsCode.length > 6) {
				this.$message.error("请输入正确的验证码");
				return;
			}
			this.queryParams = {
				memberAuthId:this.memberAuthId,//回调页面返回的msg
				phoneNumber:this.phoneNum,//手机号
				smsVerifyCode:this.verifySmsCode,//验证码
			}
			
			bindPhoneNum(this.queryParams).then(res=>{
				if(res.data.code == 200){
					this.$message.success("绑定成功");
					Vue.ls.set("TOKEN", res.data.data.access_token, res.data.data.expires_in);
					this.$router.push({ name: 'Dashboard' })
				}else{
					this.$message.error(res.data.msg)
				}

			})


		}
	}
}
</script>

<style>
	.main2 {
		position: fixed;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		text-align: center;
		background-image: url("/img/background.jpg");
		background-size: 100% 100%;
		/* margin-top: 3rem; */
	}
	.form_div{
		position: relative;
		top:6rem;
	}
	.form_item {
		width: 100%;
		height: 4.5rem;
		border-bottom: 1px solid #fff;
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.form_label {
		width: 100%;
		text-align: left;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		height: 1.5rem;
		font-size: 1.1rem;
		float: left;
		color: #000;
		}
	.input {
		width: 100%;
		}
	.input_phone {
		width: 70%;
		}
		.submit {
			height: 4rem;
			width: 100%;
			margin: 0 auto;
			padding: 0;
			text-align: center;
			}

			.submit div {
			width: 80%;
			height: 3rem;
			margin: 0 auto;
			padding: 0;
			text-align: center;
			}
			.submit div .btn {
			width: 100%;
			height: 3rem;
			}
			.v_code {
				position: flex;
				float: right;
				margin-right: 1rem;
				bottom: 1rem;
				}
				.tips_label{
					width: 100%;
					text-align: center;
					margin-top: 1rem;
					margin-bottom: 0.5rem;
					height: 1.5rem;
					font-size: 0.9rem;
					float: left;
					color: #000;
				}
</style>