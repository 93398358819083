<template>
  <div class="main">
    <header class="centerH">
      <a @click="() => $router.go(-1)" class="btn-back">
        <img src="@/assets/img-back.png" alt=""/>
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png"/>
    </header>
    <form :form="form" layout="vertical">
      <div class="form_item float_left" style="text-align:left">
        <p class="form_label">参展人数</p>
        <p style="color:#fff">
          <a-button
              class="btn"
              @click="handleReduce"
              style="margin-right:0.5rem;"
          >-
          </a-button
          >
          {{ bookingCount }}
          <a-button class="btn" @click="handlePlus" style="margin-left:0.5rem;"
          >+
          </a-button
          >
        </p>
      </div>
      <div class="form_item float_left">
        <p class="form_label">姓名</p>
        <input
            v-model="memberName"
            maxlength="20"
            class="input"
            placeholder="请输入姓名"
        />
      </div>
      <div class="form_item float_left">
        <p class="form_label">手机号</p>
        <input
            v-model="phoneNum"
            class="input_phone"
            maxlength="11"
            placeholder="请输入手机号"
        />
        <!--        <span class="v_code" v-show="codeshow" @click="sendCode"-->
        <!--          >发送验证码</span-->
        <!--        >-->
        <!--        <span class="v_code" v-show="!codeshow">{{ codeLabelMsg }}</span>-->
      </div>
      <!--      <div class="form_item float_left">-->
      <!--        <p class="form_label">验证码</p>-->
      <!--        <input-->
      <!--          v-model="verifySmsCode"-->
      <!--          class="input"-->
      <!--          maxlength="6"-->
      <!--          placeholder="请输入验证码"-->
      <!--        />-->
      <!--      </div>-->
      <div class="footer submit">
        <!-- <div>
          <a-button class="btn" @click="() => $router.go(-1)">上一步</a-button>
        </div> -->
        <div>
          <a-button class="btn" v-show="hideshow" @click="handleSubmit"
          >立即预约
          </a-button
          >
        </div>
      </div>
    </form>
    <a-modal
        v-model="modal2Visible"
        centered
        :closable="false"
        :footer="null"
        :destroyOnClose="true"
        @cancel="handleClose"
    >
      <img class="status_icon" src="@/assets/success.png"/>
      <p class="status_txt">预约成功</p>
    </a-modal>
  </div>
</template>
<script>
import {sendVerifyCode, saveOrder, getMemberInfo} from "@/api/api";

export default {
  name: "SubscribeForm",
  data() {
    return {
      form: this.$form.createForm(this),
      modal2Visible: false,
      codeshow: true,
      codeLabelMsg: "发送验证码",
      phoneNum: "",
      bookingCount: 1,
      verifySmsCode: "",
      memberName: "",
      activityId: this.$route.query.activityId,
      sessionId: this.$route.query.sessionId,
      onePersonMaxNum: this.$route.query.onePersonMaxNum,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      hideshow: true, //显示或者隐藏footer

    };
  },
  methods: {
    handlePlus() {
      this.bookingCount++;
    },
    handleReduce() {
      if (this.bookingCount == 1) {
        this.$message.warning("参展人数不得小于1");
        return;
      }
      this.bookingCount--;
    },
    sendCode() {
      if (this.phoneNum != null && this.phoneNum.length == 11) {
        this.codeshow = false;
        var countdown = 60;
        this.codeLabelMsg = "(" + countdown + ")";
        var timer = setInterval(() => {
          if (countdown <= 0) {
            clearInterval(timer);
            this.codeshow = true;
            return;
          }
          countdown--;
          this.codeLabelMsg = "(" + countdown + ")";
        }, 1000);
        sendVerifyCode({phoneNum: this.phoneNum}).then((res) => {
        });
      } else {
        alert("请输入手机号");
      }
    },
    handleSubmit() {
      console.log(this.onePersonMaxNum);
      console.log(this.activityId);
      console.log(this.sessionId);
      if (this.bookingCount == null) {
        this.$message.error("请输入参展人数");
        return;
      }
      if (this.bookingCount <= 0) {
        this.$message.error("请输入正确的参展人数");
        return;
      }
      if (this.bookingCount > this.onePersonMaxNum) {
        this.$message.error("参展人数不得大于" + this.onePersonMaxNum + "人");
        return;
      }
      if (this.memberName == null || this.memberName == "") {
        this.$message.error("请输入姓名");
        return;
      }
      if (this.memberName.length > 20) {
        this.$message.error("姓名不得超过20个字符");
        return;
      }
      if (this.phoneNum == null || this.phoneNum == "") {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (this.phoneNum.length != 11) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      // if (this.verifySmsCode == null || this.verifySmsCode == "") {
      //   this.$message.error("请输入验证码");
      //   return;
      // }
      // if (this.verifySmsCode.length > 6) {
      //   this.$message.error("请输入正确的验证码");
      //   return;
      // }
      saveOrder({
        activityId: this.activityId,
        sessionId: this.sessionId,
        bookingCount: this.bookingCount,
        memberName: this.memberName,
        memberPhone: this.phoneNum,
        // verifySmsCode: this.verifySmsCode,
      })
          .then((res) => {
            if (res.data.code != 200) {
              this.$message.error(res.data.msg);
            } else {
              this.modal2Visible = true;
              this.phoneNum = "";
              this.bookingCount = null;
              this.verifySmsCode = "";
              this.memberName = "";
              this.codeshow = true;
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    },
    handleClose() {
      this.$router.push({name: "SubscribeList"});
    },
    getMember() {
      getMemberInfo().then((res) => {
        if (res.data.code == 200) {
          let getMemberInfoResult = res.data.data;
          this.phoneNum = getMemberInfoResult.phoneNumber;
          this.memberName = getMemberInfoResult.nickName;
        }
      });
    }

  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
    this.getMember();
  },
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.hideshow = false;
      } else {
        this.hideshow = true;
      }
    },
  },
};
</script>
<style>
.main {
  height: 100%;
  padding: 0;
}

.main form {
  height: 100%;
}

.form_item {
  width: 100%;
  height: 4.5rem;
  border-bottom: 1px solid #fff;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form_label {
  width: 100%;
  text-align: left;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1.5rem;
  font-size: 1.1rem;
  float: left;
  color: #000;
}

.input {
  width: 100%;
}

.input_phone {
  width: 70%;
}

.submit {
  height: 4rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.submit div {
  width: 80%;
  height: 3rem;
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.submit div .btn {
  width: 100%;
  height: 3rem;
}

.status_txt {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.status_icon {
  width: 5rem;
  height: 5rem;
}

.v_code {
  position: flex;
  float: right;
  margin-right: 1rem;
  bottom: 1rem;
}
</style>
