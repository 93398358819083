<template>
  <div class="main2">
      <header class="centerH">
      <a @click="() => $router.push({ name: 'Dashboard' })" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>
    <div class="content_bg2">
      <img src="@/assets/sorry.png" />
      <p>功能正在开发中</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.main2 {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-image: url("/img/background.jpg");
  background-size: 100% 100%;
}

.content_bg2 {
  position: relative;
  top: 30%;
  vertical-align: middle;
  margin: 0 auto;
  background-color: #fff;
  width: 15rem;
  height: 15rem;
  text-align: center;
  border-radius: 1rem;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.content_bg2 img {
  width: 5rem;
  vertical-align: middle;
  margin-top: 3rem;
}

.content_bg2 p {
  position: relative;
  margin-top: 1rem;
  vertical-align: middle;
  top:0%;
}
</style>
