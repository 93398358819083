import axios from 'axios'
import { VueAxios } from './axios'
import Vue from 'vue'


// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    console.log("error",data)
    // if(data.code==401){
    //   window.location.href="http://sykjhd001.cn/prod-api/thrid/wx/mp/buildUrl"
    // }
  }
  return Promise.reject(error)
}
// 设置默认请求头
request.defaults.headers = {
  'Content-Type': 'application/json;charset=UTF-8',
  'X-Application-Id': "79d99a9bc0884a26841e72c11c746244",//应用id
  'X-Organization-Id': "1fbb268b8fb043ea93630d0b4d3cc013",//机构id
  'X-Site-Id': "2d3e92f95f4d4d249484f87a44b1a754",//站点id
}

// // request interceptor
request.interceptors.request.use(config => {
  const token = Vue.ls.get("TOKEN")
  if (token) {
    config.headers['Authorization'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改

  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if(response.data.code==401){
     window.location.href="/wxLogin"
  }else{
    return response
  }

}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
