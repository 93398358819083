<template>
  <div class="main">
    <header class="centerH">
      <a @click="() => $router.push({ name: 'Dashboard' })" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
      <img class="logo" src="@/assets/icon.png" />
    </header>
    <a-radio-group
      v-model="orderStatus"
      @change="handleChangeStatus"
      class="radio_group"
    >
      <a-radio-button :value="4" class="radio">
        预约订单
      </a-radio-button>
      <a-radio-button :value="5" class="radio">
        历史订单
      </a-radio-button>
    </a-radio-group>
    <ul  > <!--v-show="orders != null && orders.length > 0"-->
      <li v-for="(item, index) in orders" :key="index">
        <div class="title">
          <span>{{ item.activityName }}</span>
          <span>{{ item.orderStatusName }}</span>
        </div>
        <div class="list_text">
          <span>预约时间：</span
          ><span>{{ item.startDate + "   " + item.appointmentTime }}</span>
        </div>

        <div class="list_text">
          <span>取票码：</span><span>{{ item.certCode }}</span>
        </div>


        <div class="list_text">
          <span>预约人数：</span><span>{{ item.bookingCount }}</span>
        </div>

        <div class="list_text">
          <a-button
                  v-if="orderStatus != 5"
                  @click="handleCancel(item)"
                  class="btn float_right"
                  style="margin-bottom: 1rem;margin-left: 1rem;background-color:#fb0102;"
          >
            取消预约
          </a-button>
          <a-button
                  @click="getQRCode(item.certCode)"
                  class="btn float_right"
                  style="margin-bottom: 1rem;"
          >
            查看二维码
          </a-button>
        </div>
      </li>
    </ul>

    <div v-show="orders == null" class="content_bg2">
      <img src="@/assets/no_think.png" />
      <p>暂无订单</p>
    </div>
    <a-modal
      v-model="modal2Visible"
      centered
      :closable="false"
      :footer="null"
      :destroyOnClose="true"
    >
      <!-- <div ref="qrCodeUrl"></div> -->
      <vue-qr :text="qrcodeText"></vue-qr>
      <!-- <img src="@/assets/qrcode.png" class="qrcode" /> -->
    </a-modal>
  </div>
</template>
<script>
// import QRCode from "qrcode";
// import QRCode from 'qrcodejs2'
import vueQr from "vue-qr";
import { findHistoryOrder, findNowOrder, cancelSubscribe } from "@/api/api";
export default {
  name: "SubscrubeList",
  components: {
    vueQr,
  },
  data() {
    return {
      modal2Visible: false,
      orders: [],
      qrcodeText: "",
      orderStatus: 4,
    };
  },
  methods: {
    handleChangeStatus() {
      if (this.orderStatus == 4) {
        findNowOrder().then((res) => {
          this.orders = res.data.rows;
          if (this.orders.length == 0) {
            this.orders = null;
          }
        });
      } else {
        findHistoryOrder().then((res) => {
          this.orders = res.data.rows;
          if (this.orders.length == 0) {
            this.orders = null;
          }
        });
      }
    },
    getQRCode(content) {
      this.qrcodeText = content.toString();
      this.modal2Visible = true;
    },
    handleCancel(item){
      this.$confirm({
        title: '您确定取消预约吗？',
        content: '您当天只能取消三场预约，请注意您的取消次数',
        okText: '确认取消',
        okType: 'danger',
        cancelText: '我再想想',
        onOk: () => {
          cancelSubscribe(item.guid).then(res => {
            if(res.data.code == 200 || res.data.code == '200') {
              this.orders = []
              this.handleChangeStatus()
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
            }
          })
        },
        onCancel() {
          console.log('选择了取消');
        },
      });
    }
  },
  mounted() {
    this.handleChangeStatus();
  },
};
</script>
<style scoped>
.main {
  margin: 0 auto;
  padding-top: 3rem;
  padding-left: 0rem;
}
.main ul {
  width: 100%;
  height: 100%;
  margin-block-start: 0rem;
  padding-inline-start: 0rem;
}
.main ul li {
  width: 100%;
  float: left;
  list-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  /* padding-bottom: 1rem; */
  border-bottom: 1px solid #fff;
}
.main ul li .title {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  line-height: 1.5rem;
}

.main ul li .title span:first-child {
  color: #000000;
  font-size: 1.2rem;
  font-weight: bold;
}

.main ul li .title span:last-child {
  color: #999998;
  float: right;
}

.main ul li .list_text {
  width: 100%;
  float: left;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.2rem;
}

.main ul li .list_text span:first-child {
  color: #000;
  line-height: 1rem;
}

.main ul li .list_text span:last-child {
  color: #000;
  line-height: 1rem;
}

.qrcode {
  text-align: center;
  width: 15rem;
}

.main .content_bg2 {
  position: fixed;
  top: 30%;
  left: 20%;
  vertical-align: middle;
  margin: 0 auto;
  background-color: #fff;
  width: 15rem;
  height: 15rem;
  text-align: center;
  border-radius: 1rem;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

.main .content_bg2 img {
  width: 5rem;
  vertical-align: middle;
  /* margin-top: 3rem; */
}

.main .content_bg2 p {
  position: relative;
  margin-top: 1rem;
  vertical-align: middle;
  top: 0%;
}
.radio_group {
  width: 100%;
  height: 2.5rem;
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
}

label {
  line-height: 2.5rem;
}
.ant-radio-button-wrapper span {
  line-height: 2.5rem;
}
.radio {
  width: 50%;
  height: 100%;
  background: #fff;
  color: #000;
  border-radius: 0;
  text-align: center;
}

.radio:checked {
  width: 50%;
  background: #000;
  color: #fff;
}
.radio:active {
  width: 50%;
  background: #000;
  color: #fff;
}

.ant-radio-button-wrapper-checked {
  background: #000;
  color: #fff;
  border-color: #000;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #000;
  color: #fff;
  border-color: #000;
}
</style>
