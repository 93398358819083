import Vue from 'vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import { VueAxios } from './utils/request'
import App from './App';
import router from './router'
import animated from 'animate.css'
import Storage from 'vue-ls';
 
var options = {
  namespace: 'xj__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
};
 
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.use(Storage, options);
Vue.use(animated)
Vue.use(Antd);
Vue.use(VueAxios);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
