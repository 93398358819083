import { axios } from '@/utils/request'

const api = {
  activity:'/activity/web/list',
  activity_info:'/activity/web/show/',
  museum_info:'/activity/web/list4BookOrg',
  wechat:'/third/wx/mp/callback/',
  sendVerifyCode:'/sms/web/send/verify/code',
  order:'/activity/web/order',
  orders:'/activity/web/order',
  historyOrder:'/activity/web/history/order',
  nowOrder:'/activity/web/now/order',
  verifyOrder:'/activity/web/verifyCurrentMemberOrder',
  cancel: '/activity/web/cancel/'
}

// export default api

export function getActivityList(){
    return axios({
        url: api.activity,
        method: 'get',
    })
}

export function getActivityInfo(id){
    return axios({
        url: api.activity_info+id,
        method: 'get',
    })
}

export function getMuseumActivityInfo(){
    return axios({
        url: api.museum_info,
        method: 'get',
    })
}

export function wechatcallback(parameter) {
    return axios({
        // 客户端站点必带参数，用于标识归属
        headers: {
            "applicationId": "13fed1c66a9c4801a10d83a351cdaab7",
            "organizationId": "1fbb268b8fb043ea93630d0b4d3cc013",
            "siteId": "b6af2af3de344554ad46dfc5cd59dcb5"
        },
        url: api.wechat,
        method: 'get',
        params: parameter,
    })
}
// 发送验证码
export function sendVerifyCode(parameter){
    return axios({
        url: api.sendVerifyCode,
        method: 'post',
        params: parameter,
    })
}

export function saveOrder(parameter){
    return axios({
        url: api.order,
        method: 'post',
        data: parameter,
    })
}

export function findOrder(parameter){
    return axios({
        url: api.orders,
        method: 'get',
        params: parameter,
    })
}

export function findNowOrder(parameter){
    return axios({
        url: api.nowOrder,
        method: 'get',
        params: parameter,
    })
}

export function findHistoryOrder(parameter){
    return axios({
        url: api.historyOrder,
        method: 'get',
        params: parameter,
    })
}


export function verifyCurrentMemberOrder(parameter){
    return axios({
        url: api.verifyOrder,
        method: 'post',
        params: parameter,
    })
}

export function cancelSubscribe(guid) {
    return axios({
        url: `${api.cancel}${guid}`,
        method: 'get'
    })
}
/** 微信公众号构建授权界面 */
export function wxLoginBuild(params) {
    return axios({
        url: `/third/wx/mp/buildAuthUrl`,
        method: 'get',
        params:params
    })
}
/** 强制绑定手机号 */
export function bindPhoneNum(data){
    return axios({
        url: '/member/web/security/bindPhoneNumber',
        method: 'put',
        data: data,
    })
}
/** 微信回调接口 */
// /third/wx/mp/callback
export function wxLoginCallback(code){
    return axios({
        url: `${'/third/wx/mp/callback?code=' + code}`,
        method:'post',
    })
}

/** 查询会员信息 */
export function getMemberInfo(code){
    return axios({
        url: '/member/web/security',
        method:'get',
    })
}
/** 获取WebUrl */
// export function getwebUrl(params) {
//     return axios({
//         url: '/third/wx/web/getWebUrl',
//         method: 'get',
//         params:params
//     })
// }
/** 存储WebUrl */
export function setWebUrl(body){
    return axios({
        url:'/third/wx/web/setWebUrl',
        method:'post',
        data:body
    })
}
