// eslint-disable-next-line
/**
 * 基础路由
 * @type { *[] }
 */

import dashboard from '@/views/Dashboard'
import activityInfo from '@/views/ActivityInfo'
import museumActivity from '@/views/MuseumActivity'
import subscribeList from '@/views/SubscribeList'
import subscribeForm from '@/views/SubscribeForm'
import programming from '@/views/Programming'
import museumNav from '@/views/MuseumNav'
import callback from '@/views/Callback'
import setUserPhone from '@/views/setUserPhone'
import wxLogin from '@/views/wxLogin'
import thirdLogin from '@/views/thirdLogin'


export const constantRouterMap = [
  {
    path:"/",
    // redirect: "/setUserPhone",//绑定手机号页面
    // redirect: "/thirdLogin",//回调页面
    // redirect: "/wxLogin",//登录
    redirect: "/dashboard",//首页
  },
  {
    path: "/dashboard",
    name:"Dashboard",
    component: dashboard,
  },
  {
    path: "/dashboard/:id",
    name:"ActivityInfo",
    component: activityInfo,
  },
  {
    path: "/museum",
    name:"MuseumActivity",
    component: museumActivity,
  },
  {
    path: "/subscribe",
    name:"SubscribeList",
    component: subscribeList,
  },
  {
    path: "/programming",
    name:"Programming",
    component: programming,
  },
  {
    path: "/subscribeForm",
    name:"SubscribeForm",
    component: subscribeForm,
  },
  {
    path: "/museumNav",
    name:"MuseumNav",
    component: museumNav,
  },
  {
    path: "/callback",
    name:"Callback",
    component: callback,
  },
  {
    path: "/setUserPhone",
    name: "SetUserPhone",
    component: setUserPhone,
  },
  {
    path: "/wxLogin",
    name: "wxLogin",
    component: wxLogin,
  },
  {
    path: "/thirdLogin",
    name: "thirdLogin",
    component: thirdLogin,
  },
];
