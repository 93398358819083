<template>
  <div class="main2">
      <header class="centerH">
      <a @click="() => $router.go(-1)" class="btn-back">
        <img src="@/assets/img-back.png" alt="" />
      </a>
      <!-- 新疆美术馆 -->
            <img class="logo" src="@/assets/icon.png" />
    </header>
    <div class="content_bg">
        <p>跳转中</p>
    </div>
  </div>
</template>

<script>
import { wxLoginBuild,setWebUrl } from "@/api/api";
export default {
    created(){
        
        // 微信公众号授权
        // 新疆站点
        // let state = 'xjmsg-yy-h5';

        // let state = this.getCode(3,true)+ parseInt(new Date().getTime()/1000)+this.getCode(5);
        // let doMain = "test-xjmsg.sykjhd001.cn";//测试环境
        // let doMain = 'sykjhd001.cn';//生产环境

        let queryParams = {
            state : this.getCode(3,true)+ parseInt(new Date().getTime()/1000)+this.getCode(5),

            // doMain : "http://test-xjmsg.sykjhd001.cn",//测试
            //动态获取网址url
            // doMain:window.location.href  //应该使用这个doMain  doMain动态获取  暂时不部署

            doMain : "http://www.sykjhd001.cn",//正式环境网址
        }

       
            // 先存state和domain
            setWebUrl({state:queryParams.state,webUrl:queryParams.doMain}).then(res=>{
                if(res.data.code == 200){
                    
                    //构建
                    wxLoginBuild({state:queryParams.state}).then(result=>{
                        console.log("微信登录",result);
                                if(result.data.code == 200){
                                    // 页面跳转
                                    window.location.href = `${result.data.msg}`
                                }
                    })


                }
            })
        
         
        
    },
    methods:{
        // 获取随机值
        getCode(length, chars) {
            var charsObj=chars?'abcdefghijklmnopqrstuvwxyz':'0123456789'
            let result = ''
            for (let i = length; i > 0; --i)
                result += charsObj[Math.floor(Math.random() * charsObj.length)]
            return result
        },

    }
}
</script>

<style>
.main2 {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  background-image: url("/img/background.jpg");
  background-size: 100% 100%;
}

.content_bg {
  position: relative;
  top: 30%;
  vertical-align: middle;
  margin: 0 auto;
  background-color: #fff;
  width: 15rem;
  height: 15rem;
  text-align: center;
  border-radius: 1rem;
  color: #000;
  font-size: 1.5rem;
  font-weight: bold;
}

/* .content_bg img {
  width: 5rem;
  vertical-align: middle;
  margin-top: 3rem;
} */

.content_bg p {
position: relative;
  top: 40%;
}
</style>
