<template>
  <a-spin :spinning="loading">
    <a-icon
      slot="indicator"
      type="loading"
      style="font-size: 40px;color:#000;position: fixed;top:50%;transform: translateY(-50%);"
      spin
    />
    <div class="main">
      <header class="centerH">
        <!-- 新疆美术馆 -->
        <img class="logo" src="@/assets/icon.png" />
      </header>

      <ul class="activity_list">
        <li
          v-for="(item, index) in activityDatas"
          :key="index"
          @click="selectStyle(item)"
          class="item"
          :style="'background-image:url(' + item.listImage + ');background-repeat:cover;'"
        ></li>
      </ul>

      <div class="nav">
        <ul>
          <li
            class="list"
            @click="() => $router.push({ name: 'MuseumActivity' })"
          >
            <div>
              <img src="@/assets/cgyy.png" />
              <p>场馆预约</p>
            </div>
          </li>
          <li
            class="list"
            @click="() => $router.push({ name: 'SubscribeList' })"
          >
            <div>
              <img src="@/assets/wsyy.png" />
              <p>我的预约</p>
            </div>
          </li>
          <li class="list" @click="() => $router.push({ name: 'MuseumNav' })">
            <div>
              <img src="@/assets/ztdh.png" />
              <p>展厅导航</p>
            </div>
          </li>
          <li class="list" @click="() => $router.push({ name: 'Programming' })">
            <div>
              <img src="@/assets/wcsc.png" />
              <p>文创商城</p>
            </div>
          </li>
          <!-- <li class="list" @click="() => $router.push({ name: 'Programming' })">
            <div>
              <img src="@/assets/wldk.png" />
              <p>文旅打卡</p>
            </div>
          </li>
          <li class="list" @click="() => $router.push({ name: 'Programming' })">
            <div>
              <img src="@/assets/dfjs.png" style="width:3rem" />
              <p>巅峰竞答</p>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
  </a-spin>
</template>
<script>
import $ from "jquery";
import { getActivityList } from "@/api/api";
export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
      activityDatas: [],
    };
  },

  methods: {
    selectStyle(item) {
      if (item.hover) {
        this.$router.push({ name: "ActivityInfo", params: { id: item.guid } });
      }
      for (var i = 0; i < this.activityDatas.length; i++) {
        this.activityDatas[i].hover = false;
      }
      item.hover = true;
    },
    loadData() {
      this.loading = true;
      getActivityList()
        .then((res) => {
          console.log("res", res);
          // this.loading = false;
          this.$nextTick(function() {
            this.activityDatas = res.data.rows;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    activityDatas(val, oldVal) {
      this.$nextTick(function() {
        $(".activity_list .item").click(function() {
          var index = $(".activity_list .item").index(this);
          $(this)
            .stop(true)
            .animate({ width: "17rem", height: "100%" }, 600)
            .siblings()
            .stop(true)
            .animate({ width: "4rem", height: "100%" }, 600);
          var ul = $(".activity_list"); // 父元素
          var _x = $(this).position().left; // 相对父元素偏移量，需给父元素添加定位 position
          console.log("_x", _x);
          if (val.length > 3) {
            // if (index > 1) {
            ul.animate({ scrollLeft: 80 * (index - 1) }, 300);
            // }
          }
        });
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style>
@keyframes postAnimation {
  from {
    width: 50%;
  }
  to {
    width: 18%;
  }
}

.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* padding: 0.64rem 0.8rem 0.45rem; */
  /* padding-left: 0.64rem; */
  padding-top: 5rem;
  padding-bottom: 2rem;
  text-align: center;
  background-image: url("/img/background.jpg");
  background-size: 100% 100%;
  font-family: "SimSun";
  overflow: hidden;
  /* overflow-y: hidden; */
}

.main::-webkit-scrollbar {
  display: none;
}
.main .activity_list {
  width: auto;
  padding-left: 0.64rem;
  height: 70%;
  /* float: left; */
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.main .activity_list::-webkit-scrollbar {
  display: none;
}
.main .activity_list .item {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-size: auto 100%;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  width: 4rem;
  max-width: 19rem;
  position: relative;
}

.main .activity_list .item .title {
  margin-top: 2rem;
  width: 18%;
  height: 100%;
  color: #a47539;
  text-align: center;
  font-family: "SimSun_Bold";
  position: absolute;
}

.activity_list .item .title .arrow {
  margin-left: 1rem;
  width: 2rem;
  height: 1rem;
  position: absolute;
  z-index: 3;
}

.activity_list .item .title p {
  /* width: 16%; */
  font-size: 30px;
  text-align: center;
  font-style: bold;
  color: #e39540;
  writing-mode: tb-rl;
  margin-left: 0.5rem;
  left: 10;
  position: absolute;
  z-index: 3;
  background-image: -webkit-linear-gradient(-90deg, #e39540, #676042);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.animate__animated.animate__fadeInRight {
  --animate-duration: 0.5s;
  width: 50%;
}
.animate__animated {
  --animate-duration: 0.5s;
  width: 18%;
}

.activity_list .active {
  animation-name: postAnimation;
  animation-duration: 0.5s;
}
.nav {
  width: auto;
  height: 30%;
  vertical-align: middle;
  margin: 0 auto;
  /* margin-top: 2.5rem; */
  padding: 0;
  text-align: center;
}
.nav ul {
  /* width: 100%; */
  margin: 0 auto;
  padding: 0;
  height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  vertical-align: middle;
  list-style: none;
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;
}

.nav ul::-webkit-scrollbar {
  display: none;
}

.nav ul .list {
  display: inline-block;
  text-align: center;
  overflow-x: auto;
  white-space: nowrap;
  vertical-align: middle;
  margin: 0 auto;
  padding: 0 auto;
  width: 5.1rem;
  height: 100%;
  /* line-height: 8rem; */
  color: #fff;
}

.nav ul li div {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.nav ul li div {
  border: 0rem transparent;
  border-radius: 0rem;
  text-align: center;
  /* vertical-align: middle; */
  /* height: 8rem; */
}

.nav ul li div:hover {
  border: 0.1rem solid #fff;
  border-radius: 0.5rem;
}

.nav ul li div:active {
  border: 0.1rem solid #fff;
  border-radius: 0.5rem;
}

.nav ul li div:focus {
  border: 0rem transparent;
  border-radius: 0rem;
}

.nav ul li img {
  /* width: 60%; */
  width: 3rem;
  height: 3rem;
  /* vertical-align: middle; */
  text-align: center;
  margin: 0;
}

.nav ul li p {
  line-height: 2rem;
  /* vertical-align: middle; */
  font-family: "SimSun";
  margin: 0;
  padding: 0;
}
</style>
